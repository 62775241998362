<template>
  <div class="cmp-product-price" :class="parentClassRef">
    <ul class="price-list flex-row-nowrap align-items-baseline">
      <!-- BESPOKE -->
      <li class="price-lbl gross-label">
        <span v-translation="{ code: 'label_gross_price', type: 'label' }"></span>
      </li>
      <!-- END BESPOKE -->
      <li class="default">
        <span>{{ prices.basePrice }}</span>
      </li>
    </ul>
    <!-- BESPOKE -->
    <ul v-if="showNetPrice" class="price-list flex-row-nowrap align-items-baseline">
      <li class="price-lbl net-label">
        <span v-translation="{ code: 'label_nett_price', type: 'label' }"></span>
      </li>
      <li class="default">      
        <span>{{ prices.price }}</span>
      </li>
    </ul>
    <!-- END BESPOKE -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false }
  },
  computed: {
    // BESPOKE
    // Added userLoggedOn
    ...mapGetters(['showPrices', 'userLoggedOn']),
    showNetPrice() {
      if (this.showPrices === false && this.userLoggedOn) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style>

</style>
