<template>
  <div class="cmp-quantity-btns">
    <div class="quantity-btns-wrapper flex-row-nowrap align-center">
      <a class="quantity-btn button square small decrement fa fa-minus" @click="decrement"></a>
      <input class="quantity-fld" type="number" min="0" v-model="quantity" @change="manualQuantity">
      <a class="quantity-btn button square small increment fa fa-plus" @click="increment"></a>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      initialQuantity: { type: Number, required: false, default: 1 },
      quantityFactor: { type: Number, required: false, default: 1 },
      allowZeroQuantity: { type: Boolean, required: false, default: false }
    },
    data() {
      return {
        quantity: this.initialQuantity
      }
    },
    watch: {
      initialQuantity() {
        this.quantity = this.initialQuantity;
      }
    },
    methods: {
      quantityChanged() {
        this.$emit('quantityChanged', { quantity: parseInt(this.quantity) });
      },
      decrement() {
        if (this.quantity <= this.quantityFactor) {
          if (this.allowZeroQuantity) {
            this.quantity = 0;
            this.quantityChanged();
          } else {
            this.quantity = this.quantityFactor;
          }
        } else {
          this.quantity -= this.quantityFactor;
          this.quantityChanged();
        }
      },
      increment() {
        this.quantity += this.quantityFactor;
        this.quantityChanged();
      },
      manualQuantity() {
        if (Math.sign(this.quantity) <= 0 || this.quantity === '') {
          this.quantity = this.quantityFactor;
        } else if (this.quantity % this.quantityFactor !== 0) {
          this.quantity = Math.ceil(this.quantity / this.quantityFactor) * this.quantityFactor;
        }
        this.quantityChanged();
      }
    }
  }
</script>

<style>
</style>
