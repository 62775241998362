<template>
  <div id="elasticFilters" class="cmp-elastic-product-filters utlz-reset col-md-12" :class="'filter-count-' + filters.length">

    <transition name="slide-fade">
      <div v-if="true" class="filters-container" :ref="'filters-container'">

        <!-- <a v-if="false" @click="toggleMobileFilters()" class="close-filters-btn show-mobile">
          <i class="fa fa-times"></i>
        </a> -->

        <div v-for="(filterGroup, i) in filters" :key="'filter-group-' + i" class="filters-wrapper">
          <template v-if="filterGroup.id !== priceRangeSpecCode">
            <div class="filter-header">
              <h2>{{ getActiveFilterValue(filterGroup.id) }}</h2>
              <!-- BESPOKE NOX-71: Added fa.caret-down -->
              <i class="dd-arrow fa fa-caret-down"></i>
              <!-- END BESPOKE NOX-71 -->
            </div>
            <div class="filter-content">
              <!-- BESPOKE NOX-71: Removed .collapsed :class binding fucntionality-->
              <ul :ref="'filter-group-list' + i">
                <!-- END BESPOKE NOX-71 -->
                <li v-for="(filterObj, id) in filterGroup.value" :key="'filter-group-item-' + id">
                  <div class="checkbox-group">
                    <input type="checkbox" :id="'checkbox-filter-' + filterObj.key" @change="checkedFilter($event, { id: filterGroup.id, value: filterObj.key, elemId: 'checkbox-filter-' + filterObj.key })" :checked="filterObj.active === true">
                    <label :for="'checkbox-filter-' + filterObj.key">
                      <span class="key">
                        {{ filterObj.key }}
                      </span>
                      <!-- <span class="count">
                        ({{ filterObj.value }})
                      </span> -->
                    </label>
                  </div>
                </li>
              </ul>
              <!-- BESPOKE NOX-71: Changed v-if to false instead of using filterGroup list length -->
              <div v-if="false" class="collapse-btns" @click="collapseList('filter-group-list' + i)">
                <!-- END BESPOKE NOX-71 -->
                <div class="show-more-btn">
                  <span class="text-link">
                    <i class="fa fa-angle-double-down"></i>
                    <span v-translation="{ type: 'label', code: 'label_filter_collapse_more' }"></span>
                  </span>
                  <span class="total-collapsed count">({{ filterGroup.value.length - 5 }})</span>
                </div>
                <div class="show-less-btn">
                  <span class="text-link">
                    <i class="fa fa-angle-double-up"></i>
                    <span v-translation="{ type: 'label', code: 'label_filter_collapse_less'}"></span>
                  </span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="filter-header range">
              <h2>
                <input type="text" id="amount" readonly>
              </h2>
              <!-- BESPOKE NOX-71: Added fa.caret-down -->
              <i class="dd-arrow fa fa-caret-down"></i>
              <!-- END BESPOKE NOX-71 -->
            </div>
            <div class="filter-content">
              <div id="range-slider"></div>
            </div>
          </template>
        </div>
      </div>
      

    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
// BESPOKE
import _ from 'lodash';
import { formatPrice } from 'src/utilities/helpers';
// END BESPOKE 

export default {
  // BESPOKE
  data() {
    return {
      movedFilters: false,
      // BESPOKE
      rangeSliderSet: false
    }
  },
  // END BESPOKE
  computed: {
    ...mapState('elastic', ['filters', 'activeFilters']),
    // BESPOKE TEBE
    // Added bespokeGlobalWs
    ...mapGetters('elastic', ['showMobileFilters']),
    ...mapGetters(['bespokeGlobalWs']),
    // BESPOKE
    priceRangeSpecCode() {
      var specCode = this.bespokeGlobalWs.priceRangeSpecCode;
      return specCode !== ''  ? specCode : 'YTM5DSJDWO69NDUVRBES';
    }
    // END BESPOKE
  },
  methods: {
    ...mapActions('elastic', ['updateFilters', 'toggleMobileFilters', 'setNoProductsFound']),
    checkedFilter (ev, filter) {
      const payload = {
        filterActive: ev.target.checked,
        filterId: filter.id,
        filterValue: filter.value
      };
      if (ev.target.checked) {
        document.getElementById(filter.elemId).checked = false;
      } else {
        document.getElementById(filter.elemId).checked = true;
      }
      this.updateFilters(payload);
    },
    collapseList ($event) {
      this.$refs[$event][0].classList.toggle('collapsed');
    },
    getActiveFilterValue(filterGroupId) {
      var filterGroup = this.filters.find(x => x.id === filterGroupId);
      var activeValue = filterGroup.value.filter(x => x.active);
      if (activeValue.length) {
        return activeValue.map(x => x.key).join(', ');
      } else {
          return filterGroup.label;
      }
    },
    // BESPOKE
    updateRangeSlider(selectedMinValue, selectedMaxValue, filterGroupValues) {

      var minValue = filterGroupValues[0];
      var maxValue = filterGroupValues[filterGroupValues.length - 1];
      
      var filterValue = [];

      if (selectedMinValue >= minValue || selectedMaxValue <= maxValue) {
        
        filterGroupValues.forEach(x => {
          if (x >= selectedMinValue && x <= selectedMaxValue) {
            filterValue.push(x.replace(".", ","));
          }
        });
      }

      localStorage.setItem('priceRangePX_SELL', JSON.stringify(filterValue));

      const payload = {
        filterActive: filterValue.length > 0 ? true : false,
        filterId: this.priceRangeSpecCode,
        filterValue: filterValue,
        isRangeFilter: true
      };
      if (filterValue.length > 0) {
        this.updateFilters(payload);
      } else {
        this.setNoProductsFound(true);
      }
    }
    // END BESPOKE
  },
  updated() {
    if (!this.movedFilters && $('#elasticFilters').length > 0) {
      this.movedFilters = true;
      $('#filtersPh').append($('#elasticFilters')).addClass('active-filters');
    }

    // BESPOKE TEBE    
    if (!this.rangeSliderSet) {

      if ($('#range-slider').length > 0) {

        this.rangeSliderSet = true;
        if (this.filters.find(filterGroup => filterGroup.id === this.priceRangeSpecCode) != undefined) {
          
          var filterGroupValues = this.filters.find(filterGroup => filterGroup.id === this.priceRangeSpecCode).value.map(x => x.key.replace(",", ".")).sort((x, y) => x - y);
         
          var minValue = parseFloat(filterGroupValues[0].replace(",", "."));
          var maxValue = parseFloat(filterGroupValues[filterGroupValues.length - 1].replace(",", "."));

          var storedRange = JSON.parse(localStorage.getItem('priceRangePX_SELL'));
          var initialMinValue = minValue;
          var initialMaxValue = maxValue;

          if (storedRange !== null && this.activeFilters[this.priceRangeSpecCode] !== undefined) {
            initialMinValue = storedRange[0].replace(",", ".");
            initialMaxValue = storedRange[storedRange.length - 1].replace(",", ".");
          }

          $("#range-slider").slider({
            range: true,
            step: 0.01,
            min: minValue,
            max: maxValue,
            values: [initialMinValue, initialMaxValue],
            slide: ( event, ui ) => {
              var selectedMinValue = ui.values[0];
              var selectedMaxValue = ui.values[1];
            
              this.updateRangeSlider(selectedMinValue, selectedMaxValue, filterGroupValues);
              $("#amount").val(formatPrice(parseFloat(selectedMinValue)) + " - " + formatPrice(parseFloat(selectedMaxValue)));

              }
            });

            $("#amount").val(formatPrice(parseFloat(initialMinValue)) + " - " + formatPrice(parseFloat(initialMaxValue)));
            }
          }

        }
        // END BESPOKE
        
  },
  created() {
    this.updateRangeSlider = _.debounce(this.updateRangeSlider, 1500);
  }
}
</script>
<style>

</style>
